import React from 'react'
import { Route, Switch } from 'react-router'
import CompletedInvestmentDetails from './completedInvestmentDetails/CompletedInvestmentDetails'
import { CompletedInvestmentData } from './home/completedInvestment/CompletedInvestment'
import Home from './home/Home'
import styles from './Main.module.scss'
import OtherProjectsGallery from './otherProjectsGallery/OtherProjectsGallery'

export default function Main() {
  return (
    <div className={styles.main}>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact 
          path="/investmentdetails" 
          component={(data: CompletedInvestmentData ) => <CompletedInvestmentDetails {...data}/>}/>
        <Route exact path="/otherprojectsgallery" component={OtherProjectsGallery}/>
        <Route component={() => <div>Error 404 page not found</div>}/>
      </Switch>
    </div>
  )
}
