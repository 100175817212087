import { Translation } from "../LanguageHandler"

const polishLanguage: Translation = {
  atelier: "Atelier",
  interiorDesign: "Architektura Wnętrz",
  contemporaryArchitecture: "Architektura Współczesna",
  contact: "Kontakt",

  whatDoWeDo: "Czym się zajmujemy?",
  wholeProjectIncludingInventorying: "Kompletne projekty architektury wnętrz zawierające inwentaryzację",
  conceptualDesign: "Projekt koncepcyjny",
  executiveProject: "Projekt wykonawczy",
  authorSupervision: "Nadzór autorski",
  optionalWholeInvestmentSupervision: "Opcjonalnie całkowitą obsługę inwestycji do momentu przekazania kluczy",

  architecturalInventorying: "Inwentaryzacje architektoniczne",
  designsOfFunctionalSystems: "Projekty układów funkcjonalnych",
  designsOfSanitaryAndElectricalInstallations: "Projekty instalacji sanitarnych i elektrycznych",

  wholeIndividualFamilyHouseProjects: "Kompletne projekty indywidualne domów jednorodzinnych wraz z procesem uzyskania decyzji o pozwoleniu na budowę ",
  conceptualIndivifualBigFamilyHouseProjects: "Koncepcyjne projekty indywidualne domów jednorodzinnych i inne o skali nieprzekraczającej 400m2 powierzchni użytkowej",

  portfolioOfCompletedInvestments: "Portfolio zrealizowanych inwestycji",

  seeMore: "Zobacz więcej",
  seeLess: "Zobacz mniej",
  
  previousState: "Stan pierwotny",
  realization: "Realizacja",

  interiorDesignProjectShortDescription1: "DOM JEDNORODZINNY 130M2 | PIĄTKOWISKO",
  interiorDesignProjectLongDescription1: "Wnętrze strefy dziennej zostało zaprojektowane na etapie projektu budowlanego. Uwzględniliśmy wtedy istotne osie oraz otwarcia na otoczenie, komponując nierozłączne zespolenie domu z naturą. Główną dekoracją jadalni jest widoczny na zewnątrz klon japoński, tworzący naturalną kurtynę od strony ulicy nie ograniczając przy tym zachodniego słońca dla całej strefy. Aranżację uzupełniają surowe, czyste, skandynawskie materiały, w tym m.in. wewnętrzne okiennice, w ciągu dnia pełniące rolę ramy obrazu, który stanowi ogrodowa zieleń.",

  interiorDesignProjectShortDescription2: "APARTAMENT 64M2 | APARTAMENTY ILUMINO | ŁÓDŹ",
  interiorDesignProjectLongDescription2: "Wyjątkowo nowocześnie, niezwykle elegancko, niebywale estetycznie. Spójne wnętrze zdominowane ciepłym fornirem, nieskazitelną bielą oraz zawsze klasyczną czernią. Gabinet - prawidłowo doświetlone miejsce pracy uzupełnione czarnym wykończeniem ścian. Prosta, jasna sypialnia, dopełniona pionowymi lamelami ściennymi. Dla kontrastu oraz możliwości wnętrza - dwie wersje łazienki.",

  interiorDesignProjectShortDescription3: "APARTAMENT 88M2 | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescription3: "Pracownia Atelier Włodarczewski jako Oficjalny Architekt Wnętrz inwestycji Levityn Apartamenty prezentuje projekt koncepcyjny jednej z możliwości aranżacji wnętrza w kompleksie. Apartament nawiązuje do atutów nowego osiedla takich jak ekologiczne rozwiązania, bliskość natury, niecodzienna architektura i wiele innych!",

  interiorDesignProjectShortDescription4: "APARTAMENT 63M2 | ŁÓDŹ",
  interiorDesignProjectLongDescription4: "Apartament inwestycyjny charakteryzujący się nowoczesną klasyką, zachowany w spójnych barwach oraz zaakcentowany subtelnymi elementami wykończenia wnętrza. Niezwykle elegancka sypialnia nawiązująca do stylu strefy dziennej oraz łazienka w gustownym kontraście.",

  interiorDesignProjectShortDescription5: "APARTAMENT 36M2 | BULWARY AUGUSTÓWKA | WARSZAWA",
  interiorDesignProjectLongDescription5: "Projekt wnętrza apartamentu o powierzchni 36m2 z wyjątkowo nietypowym kształtem strefy dziennej, znajdującego się w nowej inwestycji Bulwary Augustówka na warszawskim Mokotowie. Ingerencja industrializmu w nowoczesnym wnętrzu dla młodego singla wykreowała indywidualny charakter przestrzeni.",

  interiorDesignProjectShortDescription6: "APARTAMENT 97M2 | WILLE JANA | ŁÓDŹ",
  interiorDesignProjectLongDescription6: "Projekt sypialni otwartej w nowej inwestycji mieszkaniowej Wille Jana w Łodzi. Apartament wielkości 97 m2, został zaprojektowany zgodnie z życzeniem pary inwestorów, łącząc strefę dzienną z sypialnią w jedną przestrzeń z możliwością zamknięcia strefy nocnej przesuwną płaszczyzną ukrytą w holu lokalu.",

  interiorDesignProjectShortDescription7: "DOM JEDNORODZINNY 150M2 | KSAWERÓW",
  interiorDesignProjectLongDescription7: "Renowacja wraz z rearanżacją wnętrza wiekowego domu jednorodzinnego w Ksawerowie dla przemiłej rodziny ceniącej sobie współczesne trendy, komfort oraz domowe ognisko. Pozornie nieskomplikowany projekt, wymagał jednak dopasowania planowanych rozwiązań do istniejących elementów wnętrza, uwzględniając w tym stolarkę okienną, schody, widoczną konstrukcję budynku oraz układ funkcjonalny przestrzeni mieszkalnej.",

  interiorDesignProjectShortDescription8: "DOM JEDNORODZINNY 120M2 | DOBROŃ",
  interiorDesignProjectLongDescription8: "Tymczasem w ferworze nowoczesnych i skandynawskich wnętrz, mieliśmy okazję podjęcia wyzwania aranżacji przestrzeni w ponad 17-letnim domu jednorodzinnym, w całości utrzymanym w stylu klasycznym. Pierwszym etapem odświeżenia wnętrza był aneks kuchenny w którym względem domu wspaniale odnalazł się nowoczesny klasycyzm.",

  interiorDesignProjectShortDescription9: "APARTAMENT 60M2 | POD 77 | PABIANICE",
  interiorDesignProjectLongDescription9: "Wnętrze apartamentu w nowej inwestycji POD77 tętni pozytywną energią, równocześnie zachowując czystość formy i klarowność funkcji. Nowoczesna aranżacja uzupełniona słonecznymi akcentami spotkała się z niewątpliwą aprobatą Inwestorów!",

  interiorDesignProjectShortDescription10: "APARTAMENT 87M2 | APARTAMENTY KRAKOWSKA | ŁÓDŹ",
  interiorDesignProjectLongDescription10: "Pastelowe wnętrze z dominującym motywem lastryko uzupełnione wspaniale komponującym się laminatem imitującym drewno w nowej inswestycji Apartamenty Krakowska",

  interiorDesignProjectShortDescription11: "DOM JEDNORODZINNY 280M2 | KONSTANTYNÓW ŁÓDZKI",
  interiorDesignProjectLongDescription11: "Opis powyższego projektu to kilkutomowa powieść, z tego powodu musimy ją skrócić do lakonicznych kilku słów. Aranżacja wnętrza domu jednorodzinnego w kolorach ziemi z ukrytym aneksem kuchennym, strefą dzienną z podziałem na miejsce relaksu oraz przestrzeń tv oraz przeszkloną łazienką w sypialni głównej wykończoną zgodnie z charakterem całego parteru. Zdecydowanie jeden z naszych ulubionych projektów",

  interiorDesignProjectShortDescription12: "DOM JEDNORODZINNY 260M2 | KSAWERÓW",
  interiorDesignProjectLongDescription12: "Rewolucja w 10-letnim domu jednorodzinnym w Ksawerowie. Główne oczekiwania Inwestorów - powiększony, przestronny aneks kuchenny, rearanżacja funkcji strefy dziennej oraz jasna, otwarta lecz ciepła w odbiorze przestrzeń wspólna. Na piętrze modyfikacji uległy pokoje dzieci. Jasne, skandynawskie wnętrze z akcentem stylu boho było marzeniem nastoletniej fanki koszykówki i roślin. Absolutnym przeciwieństwem były oczekiwania brata, który upodobał sobie styl loftowy z industrialnymi dodatkami. Miłośnik gekonów, Marvel'a oraz Harry'ego Potter'a był zdecydowanie usatysfakcjonowany doborem dekoracji.",

  interiorDesignProjectShortDescription13: "APARTAMENT 50M2 | OSIEDLE KRAFT | ŁÓDŹ",
  interiorDesignProjectLongDescription13: "Tym razem postawiliśmy na czystość bieli, subtelny beż w akompaniamencie oliwkowo-zgniłej zieleni, uzupełnione czarnymi dodatkami. Apartament \"z paragrafem\" charakteryzuje się stonowaną klasyką w nowoczesnej formie",

  interiorDesignProjectShortDescription14: "APARTAMENT 58M2 | PABIANICE",
  interiorDesignProjectLongDescription14: "Minimalizm, nuta Skandynawii, kombinacja z subtelnym Statuario - to wnętrze, poprzez skrupulatnie dobrane proporcje kolorystyczne oraz innowacyjne elementy wyposażenia utożsamia się z ponadczasowym designem, który tak kochamy oferować naszym Klientom",

  interiorDesignProjectShortDescription15: "STREFA WSPÓLNA | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescription15: "Projekt architektury wnętrza strefy wspólnej - komunikacji pionowej oraz poziomej w zespole budynków mieszkalnych, wielorodzinnych w Pabianicach. Głównym założeniem projektu było doprowadzenie wnętrza do spójności z formą oraz stylem architektury budynków. Zwróciliśmy szczególną uwagę na strefę wejściową, która jest zarówno wizytówka inwestycji jak i elementem witającym mieszkańców oraz potencjalnych gości",

  interiorDesignProjectShortDescription16: "APARTAMENT 86M2 | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescriptio16: "Tym razem para Inwestorów postawiła na odważne, surowe wnętrze w minimalistycznej odsłonie z dużym naciskiem na brak niepożądanych dekoracji i sztucznego bałaganu",

  interiorDesignProjectShortDescription17: "APARTAMENT 46M2 | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescription17: "Biel, czerń, drewno - rozpowszechnione zestawienie niezwykle często pojawiające się w nowoczesnych wnętrzach, jednak w tym przypadku, tak dalece odbiegające od pospolitych aranżacji. Typowy styl, dzięki odpowiednim proporcjom, rodzajowi i odcieniu drewna, dodatku wystawnego lastryko oraz stonowanym elementom wyposażenia, został przeobrażony w elegancką, jasną przestrzeń z ukrytą łazienką i wygodną, pokaźną sypialnią",

  interiorDesignProjectShortDescription18: "APARTAMENT 44M2 | NOWY NIEBRÓW | TOMASZÓW MAZOWIECKI",
  interiorDesignProjectLongDescription18: "Z przyjemnością oznajmujemy - pracownia Atelier Włodarczewski będzie miałaprzyjemność sprawowania opieki nad architekturą wnętrz w kolejnej inwestycji, tym razem w Tomaszowie Mazowieckim. Prezentujemy więc projekt koncepcyjny jednej z wielu możliwości aranżacji. Dziękujemy za zaufanie deweloperowi i wierzymy, że współpraca będzie nadzwyczajnie owocna!",

  contemporaryArchitectureProjectShortDescription1: "DOM JEDNORODZINNY 130M2 | PIĄTKOWISKO",
  contemporaryArchitectureProjectLongDescription1: "Prawidłowo zaprojektowane wnętrze zaczyna się na etapie projektu domu, który swoimi architektonicznymi właściwościami definiuje je od samego początku inwestycji! Przy każdym projekcie zwracamy uwagę między innymi na kierunki świata, w tym przypadku front działki znajduje się od zachodu, co nie ograniczyło projektu w żadnym stopniu zachowując zarówno światło zachodnie jak i prywatność w strefie dziennej. Dom jednorodzinny szyty na miarę - projekt indywidualny -",

  contemporaryArchitectureProjectShortDescription2: "DOM JEDNORODZINNY 227M2 | ŁÓDŹ",
  contemporaryArchitectureProjectLongDescription2: "Efekt współpracy z MSW Biuro Architektoniczne z ubiegłego roku - - dom jednorodzinny z dachem wielospadowym, którego... nie widać - - tym samym marzenie Inwestora sprostało Warunkom Zabudowy, które jednoznacznie wskazywały zakaz dachu płaskiego - Win-Win",

  contemporaryArchitectureProjectShortDescription3: "DOM JEDNORODZINNY 300M2 | DOBROŃ",
  contemporaryArchitectureProjectLongDescription3: "Najistotniejsze aspekty, którymi kierowaliśmy się podczas opracowania koncepcji? - - Zdecydowanie układ przestrzenno-funkcjonalny, zoptymalizowany dla młodej pary Inwestorów. - - Natura - działka od północy oraz wschodu graniczy z lasem, zadbaliśmy więc o otwarcia strefy dziennej na otaczającą przyrodę. - - Prywatność oraz strefowanie - firmowe biuro z oddzielnym wejściem od strony wjazdu na działkę w przeciwieństwie do przestrzeni osobistej, rodzinnej zlokalizowanej z dala od drogi publicznej, z tarasem oraz ogrodem w otoczeniu zieleni. - - Tudzież jak zawsze i wszędzie - Architektura!",


  otherProjects: "Pozostałe projekty",
  
  atelierDescriptionPartOne: "ARCHITEKTURA ODWIECZNIE STANOWI NIEODRĘBNĄ CZĘŚĆ ŻYCIA CZŁOWIEKA. OTACZA NAS OD PIERWSZYCH CHWIL DNIA DO JEGO ZAKOŃCZENIA, W FORMIE BUDYNKU W KTÓRYM SIĘ ZNAJDUJEMY, MIEJSCA PRACY W KTÓREJ  WYKONUJEMY ETATOWE CZYNNOŚCI, URBANISTYKI OKALAJĄCEJ POZOSTAŁE CODZIENNE AKTYWNOŚCI, KOŃCZĄC NA DOMOWYM OGNISKU, W KTÓRYM KREUJEMY WŁASNĄ ŚWIADOMOŚĆ, CHARAKTER ORAZ AMPLITUDĘ OSOBISTEGO SAMOPOCZUCIA.",
  atelierDescriptionPartTwo: "STUDIO PROJEKTOWE ATELIER WŁODARCZEWSKI ZOSTAŁO UTWORZONE W CELU DĄŻENIA DO STWORZENIA DOSKONAŁEJ PRZESTRZENI FUNKCJONALNEJ DLA KORZYSTAJĄCYCH Z JEGO USŁUG KLIENTÓW. NA PODSTAWIE IDENTYFIKACJI DOCELOWYCH USŁUGOBIORCÓW/UŻYTKOWNIKÓW DANEGO MIEJSCA, WIEDZY NABYTEJ NA KRAJOWYCH ORAZ ZAGRANICZNYCH UCZELNIACH WYŻSZYCH, KLUCZOWEGO DOŚWIADCZENIA PROJEKTOWEGO UZYSKANEGO PODCZAS PRAKTYK, STAŻY W UZNANYCH PRACOWNIACH ORAZ POPRZEDZAJĄCYCH PROJEKT ANALIZACH ARCHITEKTONICZNYCH, TWORZONY JEST PRODUKT FINALNY  DOSTOSOWANY DO POTRZEB ZAMAWIAJĄCEGO POD WZGLĘDEM ERGONOMII, ESTETYKI, FUNKCJONALNOŚCI, OBECNYCH TRENDÓW A TAKŻE ZAŁOŻONEGO PRZEDWSTĘPNEGO KOSZTORYSU.",
  atelierDescriptionPartThree: "ARCHITEKTURA WNĘTRZA PRZEZ WIELU DOTYCHCZAS POSTRZEGANA JAKO DODATEK POCHŁANIAJĄCY FINANSE INWESTORÓW ZMIENIŁA SWÓJ WIZERUNEK NA PRZESTRZENI UBIEGŁEJ DEKADY, UTOŻSAMIAJĄC SIĘ JEDNOCZEŚNIE Z KWESTIĄ KONIECZNĄ PRZED PRZYSTĄPIENIEM DO UŻYTKOWANIA POTENCJALNEGO APARTAMENTU/DOMU/LOKALU.  STUDIO OFERUJE ZARÓWNO KONCEPCYJNE JAK I KOMPLETNE, WYKONAWCZE PROJEKTY WNĘTRZ “USZYTE NA MIARĘ” NAJWYŻSZEJ JAKOŚCI, KŁADĄC NAJWIĘKSZY NACISK NA WYMAGANIA ORAZ OCZEKIWANIA KLIENTA.  WNĘTRZE WEDŁUG ZAŁOŻONEJ FILOZOFII FIRMOWEJ STANOWI FORMĘ ODZWIERCIEDLENIA CHARAKTERU KORZYSTAJĄCYCH Z NIEGO UŻYTKOWNIKÓW, IDENTYFIKUJĄCĄ SIĘ Z CODZIENNYMI PODSTAWOWYMI POTRZEBAMI, CZEGO WYNIKIEM JEST SZEROKI ZAKRES STYLISTYCZNY PROPONOWANYCH PRZEZ STUDIO KONWENCJI:",
  atelierDescriptionPartFour: "MINIMALISTYCZNA | NOWOCZESNA | SKANDYNAWSKA | NOWOCZESNA KLASYCZNA | KLASYCZNA LOFTOWA | INDUSTRIALNA | GLAMOUR | EKOLOGICZNA | RUSTYKALNA | PROWANSALSKA ORAZ WIELE INNYCH NA ŻYCZENIE INWESTORA",

  offer: "Oferta",

  offerSubTitlePartOne: "INWENTARYZACJA ARCHITEKTONICZNA + WIZJA LOKALNA",
  offerDescriptionPartOne: "PRZED PRZYSTĄPIENIEM DO PROJEKTU, W PRZYPADKU W KTÓRYM NIE JESTEŚMY W DYSPOZYCJI RYSUNKÓW TECHNICZNYCH POWYKONAWCZYCH PROJEKTOWANEGO POMIESZCZENIA, NALEŻY WYKONAĆ INWENTARYZACJĘ ARCHITEKTONICZNĄ W CELU UZYSKANIA SPÓJNYCH PODKŁADÓW PROJEKTOWYCH ZAWIERAJĄCYCH NIEZBĘDNE POMIARY DZIĘKI KTÓRYM JEST MOŻLIWE STWORZENIE PROJEKTU WYKONAWCZEGO.",

  offerSubTitlePartTwo: "PROJEKT WNĘTRZA MIESZKALNEGO",
  offerDescriptionPartTwo: "STUDIO OFERUJE PROJEKTY WNĘTRZ LOKALI MIESZKALNYCH ZARÓWNO RYNKU PIERWOTNEGO JAK I WTÓRNEGO, APARTAMENTÓW W KOMPLEKSACH WIELORODZINNYCH, ARANŻACJĘ POMIESZCZEŃ DOMÓW JEDNO- LUB WIELORODZINNYCH.",

  offerSubTitlePartThree: "PROJEKT WNĘTRZA KOMERCYJNEGO",
  offerDescriptionPartThree: "WYKONYWANE SĄ RÓWNIEŻ PROJEKTY WNĘTRZ UŻYTECZNOŚCI PUBLICZNEJ TAKICH JAK POKOJE HOTELOWE, KANCELARIE, RESTAURACJE, BUTIKI, SALONY, GALERIE, ATELIER, STREFY WEJŚCIOWE, POMIESZCZENIA BIUROWE I INNE.",

  offerSubTitlePartFour: "PROJEKT ARCHITEKTONICZNY KONCEPCYJNY",
  offerDescriptionPartFour: "DODATKOWO STUDIO OFERUJE PROJEKTY ARCHITEKTONICZNE KONCEPCYJNE BUDYNKÓW WOLNOSTOJĄCYCH O SKALI NIEPRZEKRACZAJĄCEJ POWIERZCHNI UŻYTKOWEJ 400 M2 LUB NA ŻYCZENIE KLIENTA KOMPLETNE PROJEKTY INDYWIDUALNE DOMÓW JEDNORODZINNYCH WRAZ Z PROCESEM UZYSKANIA DECYZJI O POZWOLENIU NA BUDOWĘ.",

  leadingArchitect: "Architekt prowadzący",
  interiorDesigner: "Projektant wnętrz",
  architect: "Architekt",
  graphicDesigner: "Grafik",

  education: "Wykształcenie",

  educationPartOne: "ARCHITECTURE  -  INTERNATIONAL FACULTY OF ENGINEERING  -  ŁÓDŹ, POLSKA",
  educationPartTwo: "EUROPEAN PROJECT SEMESTER  -  UNIVERSITY OF ANTWERP  -  ANTWERPIA, BELGIA",
  educationPartThree: "ARCHITEKTURA I URBANISTYKA  -  POLITECHNIKA ŁÓDZKA  -  ŁÓDŹ, POLSKA",

  achievements: "Osiągnięcia",
  thirdPlace: "III Miejsce",
  achievementPartOne: "KONKURS URBANISTYCZNY NA KONCEPCJĘ ZAGOSPODAROWANIA TERENU MIEJSKIEGO OŚRODKA SPORTU I REKREACJI W PABIANICACH",

  realizations: "Realizacje", 

  poland: "Polska",
  ireland: "Irlandia",

  lodz: "Łódź",
  warsaw: "Warszawa",
  ksawerow: "Ksawerów",
  lask: "Łask",
  dobron: "Dobroń",
  piatkowisko: "Piątkowisko",
  dublin: "Dublin",
  slawa: "Sława",
  wroclaw: "Wrocław",
  radomsko: "Radomsko",
  slupsk: "Słupsk",
  wiaczynDolny: "Wiączyń Dolny",
  zgierz: "zgierz",

  inCooperationWithMswPiotrMarcinkowski: "WE WSPÓŁPRACY Z MSW BIURO ARCHITEKTONICZNE PIOTR MARCINKOWSKI (WNĘTRZA)",
  inCooperationWithBonarteAgnieszkaPierzchala: "WE WSPÓŁPRACY Z BONARTE AGNIESZKA PIERZCHAŁA (WNĘTRZA)",

  realizationAloneSixteen: "WNĘTRZE APARTAMENTU - CASA MIA",
  realizationAloneFifteen: "WNĘTRZE APARTAMENTU - APARTAMENTY ILUMINO II",
  realizationAloneFourteen: "WNĘTRZE SEGMENTU - KOMFORTOWE OSIEDLE",
  realizationAloneThirteen: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationAloneTwelve: "WNĘTRZE APARTAMENTU - GÓRNA",
  realizationAloneEleven: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationAloneTen: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationAloneNine: "WNĘTRZE APARTAMENTU",
  realizationAloneEight: "KONCEPCJA DOMU JEDNORODZINNEGO",
  realizationAloneSeven: "KONCEPCJA WNĘTRZA APARTAMENTU - WILLE JANA",
  realizationAloneSix: "WNĘTRZE APARTAMENTU - BULWARY AUGUSTÓWKA",
  realizationAloneFive: "WNĘTRZE DOMU JEDNORODZINNEGO - ŁAGIEWNIKI",
  realizationAloneFour: "KONCEPCJA DOMU JEDNORODZINNEGO - JULIANÓW",
  realizationAloneThree: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationAloneTwo: "WNĘTRZE SALONU OPTYCZNEGO - CH MAGNOLIA PARK",
  realizationAloneOne: "WNĘTRZE DOMU JEDNORODZINNEGO",

  realizationInCoopWithMswPiotrMarcinkowskiTen: "KONCEPCJA WNĘTRZA DOMU JEDNORODZINNEGO - DOLINA ŁÓDKI",
  realizationInCoopWithMswPiotrMarcinkowskiNine: "WNĘTRZE STREFY WSPÓLNEJ AQUAPARKU W RADOMSKU",
  realizationInCoopWithMswPiotrMarcinkowskiEight: "WNĘTRZE APARTAMENTU - NOVO PARK",
  realizationInCoopWithMswPiotrMarcinkowskiSeven: "WNĘTRZE STREFY WSPÓLNEJ - OSIEDLE OSKAR",
  realizationInCoopWithMswPiotrMarcinkowskiSix: "WNĘTRZE STREFY WSPÓLNEJ AQUAPARKU “TRZY FALE”  W SŁUPSKU",
  realizationInCoopWithMswPiotrMarcinkowskiFive: "KONCEPCJA WNĘTRZA DOMU JEDNORODZINNEGO - ŁAGIEWNIKI",
  realizationInCoopWithMswPiotrMarcinkowskiFour: "KONCEPCJA WNĘTRZA DOMU JEDNORODZINNEGO - RATAJA PARK",
  realizationInCoopWithMswPiotrMarcinkowskiThree: "WNĘTRZE PRZYCHODNI LEKARSKIEJ - DOLINA ŁÓDKI",
  realizationInCoopWithMswPiotrMarcinkowskiTwo: "WNĘTRZE STREFY WSPÓLNEJ - NOVO PARK",
  realizationInCoopWithMswPiotrMarcinkowskiOne: "KONCEPCJA WNĘTRZA APARTAMENTU - NOVO PARK",

  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyEight: "WNĘTRZE APARTAMENTU - APARTAMENTY ARTE",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentySeven: "WNĘTRZE MIESZKANIA - OLECHÓW",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentySix: "WNĘTRZE DOMU JEDNORODZINNEGO - STARE ZŁOTNO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyFive: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyFour: "WNĘTRZE DOMU JEDNORODZINNEGO - AUGUSTÓW",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyThree: "WNĘTRZE MIESZKANIA - OLECHÓW",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyTwo: "WNĘTRZE MIESZKANIA - ŚRÓDMIEŚCIE",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyOne: "WNĘTRZE APARTAMENTU - ATRIUM RADOGOSZCZ (3)",

  realizationInCoopWithBonarteAgnieszkaPierzchalaTwenty: "WNĘTRZE APARTAMENTU - ATRIUM RADOGOSZCZ (2)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaNineteen: "WNĘTRZE APARTAMENTU - CENTRAL PARK APARTMENTS (2)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaEighteen: "WNĘTRZE MIESZKANIA - OLECHÓW",
  realizationInCoopWithBonarteAgnieszkaPierzchalaSeventeen: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaSixteen: "WNĘTRZE APARTAMENTU - ATRIUM RADOGOSZCZ (1)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaFifteen: "WNĘTRZE APARTAMENTU - TOMCIA PALUCHA (2)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaFourteen: "WNĘTRZE APARTAMENTU - TOMCIA PALUCHA (1)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaThirteen: "WNĘTRZE APARTAMENTU - APARTAMENTY NIEMCEWICZA 17",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwelve: "WNĘTRZE APARTAMENTU - CENTRAL PARK APARTMENTS (1)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaEleven: "WNĘTRZE APARTAMENTU - NARUTOWICZA RESIDENCE (4)",

  realizationInCoopWithBonarteAgnieszkaPierzchalaTen: "WNĘTRZE TOALET PUBLICZNYCH",
  realizationInCoopWithBonarteAgnieszkaPierzchalaNine: "WNĘTRZE DOMU JEDNORODZINNEGO - BRZOZOWA ALEJA",
  realizationInCoopWithBonarteAgnieszkaPierzchalaEight: "WNĘTRZE DOMU LETNISKOWEGO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaSeven: "WNĘTRZE APARTAMENTU - NARUTOWICZA RESIDENCE (3)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaSix: "WNĘTRZE APARTAMENTU - NARUTOWICZA RESIDENCE (2)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaFive: "WNĘTRZE APARTAMENTU - NARUTOWICZA RESIDENCE (1)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaFour: "WNĘTRZE DOMU JEDNORODZINNEGO - NOWOSOLNA",
  realizationInCoopWithBonarteAgnieszkaPierzchalaThree: "WNĘTRZE POWIERZCHNI BIUROWYCH - ŚRÓDMIEŚCIE",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwo: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaOne: "WNĘTRZE SALONU SZTUKATERII GIPSOWEJ",

  office: "Biuro"
}

export default polishLanguage