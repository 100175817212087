import React from 'react'
import { otherProjectsData } from '../home/interiorDesign/InteriorDesignData'
import styles from './OtherProjectsGallery.module.scss'

export default function OtherProjectsGallery() {
  return (
    <div className="row no-gutters">
      {otherProjectsData.imagesNames.map(imageName => (
        <div className={"col-12 col-lg-6"} key={Math.random()}>
          <img 
            className = {styles.otherProjectImage}
            src = {`${process.env.PUBLIC_URL}${otherProjectsData.imagesBasePath}${imageName}`}
            alt = {`Other project`}/>
        </div>
      ))}
    </div>
  )
}
