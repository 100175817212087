import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { scrollWithOffset } from '../../../../helpers/ScrollHelper'
import { languageHandler } from '../../../App'
import styles from "./CompletedInvestment.module.scss"

export type CompletedInvestmentData = {
  imagesBasePath: string
  imagesNames: string[]
  shortDescriptionStringId: string
  longDescriptionStringId: string
}

type CompletedInvestmentProps = {
  completedInvestmentData: CompletedInvestmentData
}

export default function CompletedInvestment(props: CompletedInvestmentProps) {
  const data = props.completedInvestmentData

  return (
    <HashLink to={{
      pathname: "/investmentdetails",
      hash: "#",
      state: data}}
      scroll={(el) => scrollWithOffset(el, -100)}>
      <div className={styles.completedInvestment}>
        <span className = {styles.shortDescription}>{languageHandler.getString(data.shortDescriptionStringId)}</span>
        <img 
          className = {styles.backgroundImage}
          src = {`${process.env.PUBLIC_URL}${data.imagesBasePath}${data.imagesNames[0]}`}
          alt = {`Project: ${data.shortDescriptionStringId}`}/>
      </div>
    </HashLink>
  )
}
