import { getProjectData } from "../../../../helpers/CommonFuntions";
import { CompletedInvestmentData } from "../completedInvestment/CompletedInvestment";

const SHORT_DESCRIPTION = "interiorDesignProjectShortDescription"
const LONG_DESCRIPTION = "interiorDesignProjectLongDescription"
const BASE_PATH = "/assets/img/interiorDesign/project_"

export const interiorDesignData: CompletedInvestmentData[]  = [
  getProjectData("11", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg",
    "12.jpg", "13.jpg", "14.jpg", "15.jpg", "16.jpg", "17.jpg", "18.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("1", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("10", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("18", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("3", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg",
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("15", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("12", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg",
    "12.jpg", "13.jpg", "14.jpg", "15.jpg", "16.jpg", "17.jpg", "18.jpg", "19.jpg", "20.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("14", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("16", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg",
    "12.jpg", "13.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("2", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg",
    "12_previousState.jpg", "13.jpg", "14.jpg", "15.jpg", "16.jpg", "17.jpg", "18.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("17", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("13", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("4", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8_previousState.jpg", "9.jpg", "10.jpg",
    "11.jpg",
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("5", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8_realization.jpg", "9.jpg", "10.jpg",
    "11.jpg",
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("9", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg"
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("7", [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg",
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("8", [
    "0.jpg", "1.jpg", "2.jpg", "3_realization.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8_previousState.jpg", 
    "9.jpg", "10.jpg", "11.jpg",
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("6", [
    "0.jpg", "1.jpg",
  ], BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),
]

export const otherProjectsData = {
  imagesBasePath: "/assets/img/interiorDesign/otherProjects/",
  imagesNames: [
    "0.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg",
    "12.jpg", "13.jpg", "14.jpg", "15.jpg", "16.jpg", "17.jpg", "18.jpg", "19.jpg", "20.jpg", "21.jpg", "22.jpg",
    "23.jpg", "24.jpg", "25.jpg", "26.jpg", "27.jpg", "28.jpg", "29.jpg", "30.jpg", "31.jpg", "32.jpg", "33.jpg", 
    "34.jpg", "35.jpg"
  ],
}
