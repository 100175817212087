import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { scrollWithOffset } from '../../../../../helpers/ScrollHelper'
import { languageHandler } from '../../../../App'
import { otherProjectsData } from '../InteriorDesignData'
import styles from "./OtherProjects.module.scss"

export default function OtherProjects() {

  return (
    <HashLink to={{
      pathname: "/otherprojectsgallery",
      hash: "#",
      state: otherProjectsData}}
      scroll={(el) => scrollWithOffset(el, -100)}>
      <div className={styles.otherProjects}>
        <span className = {styles.shortDescription}>{languageHandler.getString("otherProjects")}</span>
        <img 
          className = {styles.backgroundImage}
          src = {`${process.env.PUBLIC_URL}${otherProjectsData.imagesBasePath}${otherProjectsData.imagesNames[0]}`}
          alt = {`Other projects`}/>
      </div>
    </HashLink>
  )
}
