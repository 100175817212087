import { CompletedInvestmentData } from "../app/main/home/completedInvestment/CompletedInvestment"

export const getProjectData = (
  id: string,
  imagesNames: string[],
  basePath: string,
  shortDescriptionIdPrefix: string,
  longDescriptionIdPrefix: string): CompletedInvestmentData => {
  return {
    imagesBasePath: `${basePath}${id}/`,
    imagesNames: imagesNames,
    shortDescriptionStringId: `${shortDescriptionIdPrefix}${id}`,
    longDescriptionStringId: `${longDescriptionIdPrefix}${id}`,
  }
}
